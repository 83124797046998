/**=====================
    55. knowledgebase CSS start
==========================**/
.knowledgebase-bg {
  height: 500px;
  margin-bottom: 30px;
  border-radius: 10px;
}
.knowledgebase-search {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  left: 5%;
  h3 {
    font-weight: 600;
  }
  .form-inline {
    background-color: $white;
    width: 500px;
    padding: 12px 50px;
    border-radius: 10px;
    border: 1px solid $light-gray;
    margin-top: 10px;
    position: relative;
    ::placeholder {
      color: $gray-60;
    }
    svg {
      position: absolute;
      left: 20px;
      width: 20px;
      top: 17px;
      stroke: $gray-60;
    }
  }
}
.knowledge-details{
  .blog-box{
    img{
      border-radius: 10px;
    }
  }
  .job-sidebar{
    .categories{
      ul{
        li{
          span.badge{
            padding: 4px 5px 3px;
          }
        }
      }
    }
  }
}
.browse {
  > div {
    margin-bottom: 30px;
  }
  .browse-articles {
    background-color: $white;
    padding: 30px;
    border-radius: 10px;
    h6 {
      font-weight: 600;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid $light-gray;
      color: $semi-dark;
      padding-left: 25px;
      position: relative;
      line-height: 1.6;
      span {
        svg {
          width: 16px;
          height: 16px;
          margin-right: 10px;
          vertical-align: text-top;
          position: absolute;
          left: 0;
          top: 3px;
        }
      }
    }
    ul {
      li {
        border-radius: 8px;
        position: relative;
        padding-top: 8px;
        padding-bottom: 8px;
        cursor: pointer;
        &:last-child {
          padding-bottom: 0;
        }
        h5 {
          vertical-align: text-top;
          font-size: 14px;
          margin-bottom: unset;
          color: $light-text;
          width: 100%;
          padding-left: 20px;
          line-height: 1.6;
          font-weight: 500;
          position: relative;
          span.badge {
            margin-left: auto;
          }
          svg {
            width: 14px;
            height: 14px;
            margin-right: 10px;
            vertical-align: text-top;
            position: absolute;
            left: 0;
            top: 4px;
          }
        }
        a {
          color: $theme-body-font-color;
          display: inline-flex;
          align-items: center;
          width: 100%;
        }
        &:hover {
          h5 {
            color: $primary-color;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1199px) {
  .knowledgebase-bg {
    height: 400px;
  }
  .knowledgebase-search {
    .form-inline {
      width: 400px;
      padding: 7px 45px;
      svg {
        top: 14px;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .browse {
    .browse-articles {
      h6 {
        margin-bottom: 10px;
      }
    }
  }
}
@media only screen and (max-width: 575px) {
  .browse {
    .browse-articles {
      padding: 20px;
      h6 {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
  }
  .knowledgebase-bg {
    height: 250px;
  }
  .knowledgebase-search {
    width: calc(100% - 30px);
    left: 15px;
    height: calc(100% - 30px);
    background-color: rgba($white, 0.7);
    .form-inline {
      width: 90%;
      padding: 3px 45px;
      svg {
        top: 8px;
      }
    }
    > div {
      width: 100%;
      text-align: center;
      padding: 15px;
      border-right: 10px;
      h3 {
        font-size: 20px;
        margin-bottom: 0;
      }
      .form-group {
        margin-bottom: 0;
      }
    }
  }
}
@media only screen and (max-width: 360px) {
  .browse {
    .browse-articles {
      ul {
        li {
          h5 {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            span {
              &.badge {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
/**===========================
    55. knowledgebase CSS end
==========================**/