@font-face {
    font-family: 'SF Pro Text';
    src: url('../../fonts/theme-font/SFProText-SemiboldItalic.woff2') format('woff2'),
        url('../../fonts/theme-font/SFProText-SemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url('../../fonts/theme-font/SFProText-LightItalic.woff2') format('woff2'),
        url('../../fonts/theme-font/SFProText-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url('../../fonts/theme-font/SFProText-RegularItalic.woff2') format('woff2'),
        url('../../fonts/theme-font/SFProText-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url('../../fonts/theme-font/SFProText-Regular.woff2') format('woff2'),
        url('../../fonts/theme-font/SFProText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url('../../fonts/theme-font/SFProText-Medium.woff2') format('woff2'),
        url('../../fonts/theme-font/SFProText-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url('../../fonts/theme-font/SFProText-Regular.woff2') format('woff2'),
        url('../../fonts/theme-font/SFProText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url('../../fonts/theme-font/SFProText-Semibold.woff2') format('woff2'),
        url('../../fonts/theme-font/SFProText-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url('../../fonts/theme-font/SFProText-MediumItalic.woff2') format('woff2'),
        url('../../fonts/theme-font/SFProText-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url('../../fonts/theme-font/SFProText-Medium.woff2') format('woff2'),
        url('../../fonts/theme-font/SFProText-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url('../../fonts/theme-font/SFProText-Semibold.woff2') format('woff2'),
        url('../../fonts/theme-font/SFProText-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url('../../fonts/theme-font/SFProText-HeavyItalic.woff2') format('woff2'),
        url('../../fonts/theme-font/SFProText-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url('../../fonts/theme-font/SFProText-Light.woff2') format('woff2'),
        url('../../fonts/theme-font/SFProText-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url('../../fonts/theme-font/SFProText-Bold.woff2') format('woff2'),
        url('../../fonts/theme-font/SFProText-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url('../../fonts/theme-font/SFProText-BoldItalic.woff2') format('woff2'),
        url('../../fonts/theme-font/SFProText-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url('../../fonts/theme-font/SFProText-Heavy.woff2') format('woff2'),
        url('../../fonts/theme-font/SFProText-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url('../../fonts/theme-font/SFProText-Bold.woff2') format('woff2'),
        url('../../fonts/theme-font/SFProText-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url('../../fonts/theme-font/SFProText-Light.woff2') format('woff2'),
        url('../../fonts/theme-font/SFProText-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF Pro Text';
    src: url('../../fonts/theme-font/SFProText-Heavy.woff2') format('woff2'),
        url('../../fonts/theme-font/SFProText-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Amanda Signature';
    src: url('../../fonts/theme-font/AmandaSignatureRegular.woff2') format('woff2'),
        url('../../fonts/theme-font/AmandaSignatureRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}