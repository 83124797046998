/**=====================
    22. Scrollbar CSS Start
==========================**/
[data-simplebar] {
  position: unset;
}
.simplebar-mask {
  top: 95px;
}
.simplebar-track {
  right: -2px;
  &.simplebar-vertical {
    top: 100px;
    width: 10px;
  }
  &.simplebar-horizontal {
    visibility: hidden !important;
  }
}
.simplebar-scrollbar {
  &:before {
    background: rgba($primary-color, 0.2);
  }
}
.horizontal-scroll{
  height: 440px;
}
.both-side-scroll, .vertical-scroll{
  height: 460px;
  overflow: hidden;
}
/**=====================
    22. Scrollbar CSS Ends
==========================**/