/**=====================
    26. Table  CSS Start
==========================**/
thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: $light-gray;
}
.pills-component {
  width: 20%;
}
.table {
  margin-bottom: $table-b-margin;
  > :not(:last-child) {
    > :last-child {
      > * {
        border-bottom-color: $light-gray;
      }
    }
  }
  th,
  td {
    padding: $table-padding;
  }
  &.table-primary {
    th {
      color: $white;
    }
    td {
      color: $white;
    }
  }
  thead {
    &.bg-primary {
      tr {
        th {
          color: $white;
        }
      }
    }
    &.table-primary {
      tr {
        th {
          color: $primary-color;
        }
      }
    }
    &.table-success {
      tr {
        th {
          color: $success-color;
        }
      }
    }
  }
  tfoot {
    &.table-success {
      tr {
        td,
        th {
          color: $success-color;
        }
      }
    }
  }
  th {
    color: $table-heading-color;
    font-weight: 500;
  }
  td {
    color: $table-row-color;
  }

  [class*="bg-"] {
    color: $white;
  }
  .bg-light {
    color: $theme-body-font-color;
  }
  tfoot {
    font-weight: $table-footer-font-weight;
  }
}
.table-striped {
  tbody {
    tr {
      &:nth-of-type(odd) {
        > *{
          --bs-table-accent-bg: unset;
        }
      }
    }
  }
}
.table-striped{
  &.table-styling{
    > tbody{
      > tr{
        &:nth-of-type(odd){
          &:hover{
            > * {
              color: $primary-color;
            }
          }
          > * {
            --bs-table-accent-bg: none;
            color: $white;
          }
        }
      }
    }
  }
  &.bg-primary{
    > tbody{
      > tr{
        &:nth-of-type(odd){
          * {
            color: $white;
          }
        }
      }
    }
  }
}
.table-styling {
  &.table-primary {
    &.table-striped {
      tbody {
        tr {
          color: $white;
          &:nth-of-type(odd) {
            --bs-table-accent-bg: unset;
            &:hover {
              color: var(--theme-default);
            }
          }
          &:nth-of-type(even) {
            &:hover {
              th,
              td {
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}
.table-dark {
  th {
    color: $white;
  }
}
.table-inverse {
  color: $white;
  background-color: $table-inverse-bg-color;
  th,
  td {
    border-color: $white;
    color: $white;
  }
  &.table-bordered {
    border: 0;
  }
}
.table[class*="bg-"] {
  th,
  td {
    color: $white;
  }
}
.table-hover {
  > tbody {
    > tr:hover {
      --bs-table-accent-bg: unset;
    }
  }
}
.table-double {
  border-left: 4px double #dee2e6;
  border-right: 4px double #dee2e6;
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-style: double;
    border-width: 4px;
    border-right: 0;
    border-left: 0;
  }
}
.table-dotted {
  border-left: 2px dotted #dee2e6;
  border-right: 2px dotted #dee2e6;
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-style: dotted !important;
    border-width: 2px;
    border-right: 0;
    border-left: 0;
  }
}
.table-dashed {
  border-left: 2px dashed #dee2e6;
  border-right: 2px dashed #dee2e6;
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-style: dashed !important;
    border-width: 2px;
    border-right: 0;
    border-left: 0;
  }
}
//Sizing class
.table-xl {
  td,
  th {
    padding: $table-xl-padding;
    font-size: $table-xl-font;
  }
}
.table-lg {
  td,
  th {
    padding: $table-lg-padding;
    font-size: $table-lg-font;
  }
}
.table-de {
  td,
  th {
    padding: $table-de-padding;
  }
}
.table-sm {
  th,
  td {
    padding: $table-sm-padding;
    font-size: $table-sm-font;
  }
}
.table-xs {
  th,
  td {
    padding: $table-xs-padding;
    font-size: $table-xs-font;
  }
}
//Table Border
.table-border-horizontal {
  tr,
  th,
  td {
    border-top: 1px solid $horizontal-border-color;
    padding: $horizontal-padding;
  }
}
.table-border-vertical {
  tr,
  th,
  td {
    border-top: 0;
    border-right: 1px solid $horizontal-border-color;
  }
  tr {
    td {
      border-bottom: 0;
      &:last-child {
        border-right: none;
      }
    }
  }
  tbody {
    tr {
      th {
        border-bottom: 0;
      }
    }
  }
}
.table-bordernone {
  td {
    border: none !important;
  }
  thead {
    th {
      border: none !important;
    }
  }
}
.table-striped {
  tbody {
    tr {
      &:nth-of-type(odd) {
        --bs-table-accent-bg: rgba(245, 247, 250, 0.5);
      }
    }
  }
}
.table-bordered {
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: $horizontal-border-color;
  }
}
.grid-wrrapper {
  .table-striped {
    tbody {
      tr {
        &:nth-of-type(odd) {
          background-color: rgba($primary-color, 0.1);
          --bs-table-accent-bg: unset;
          th,
          td {
            color: $primary-color;
          }
        }
      }
    }
  }
}
.table-styling {
  &.table-primary {
    &.table-striped {
      tbody {
        tr {
          color: $white;
          &:nth-of-type(even) {
            &:hover {
              th,
              td {
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}
.support-table {
  .rdt_TableHeader {
    display: none;
  }
  .rdt_Table {
    .rdt_TableHead{
      .rdt_TableHeadRow{
        .rdt_TableCol{
          &:first-child,
          &:nth-child(2),
          &:nth-last-child(2){
            min-width: 228px;
          }
          &:last-child{
            .rdt_TableCol_Sortable{
              justify-content: center;
            }
          }
          .rdt_TableCol_Sortable{
            font-weight: 600;
            font-size: 14px;
            justify-content: flex-start;
            span{
              display: none;
            }
          }
        }
      }
    }
    .rdt_TableBody {
      .rdt_TableRow{
        .rdt_TableCell{
          justify-content: flex-start;
          &:first-child,
          &:nth-child(2),
          &:nth-last-child(2){
            min-width: 228px;
          }
          &:last-child{
            justify-content: center;
          }
        }
      }
      .rdt_TableCell {
        img {
          border-radius: 100%;
        }
        &:nth-child(5) {
          > div {
            width: 100%;
          }
        }
      }
    }
  }
}
.support-table {
  .progress-showcase{
    width: 100px;
  }
}
/**=====================
    26.Table CSS Ends
==========================**/